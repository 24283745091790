import { combineReducers } from 'redux';
import application from './application';
import invoices from './invoices/invoices.reducer';

export interface ApplicationState {
  application: any;
  invoices: any;
}

const rootReducers = combineReducers({
  application,
  invoices,
});

export default rootReducers;
