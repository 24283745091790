import Swal from 'sweetalert2';
import { getMethodFullName } from './get-method';
import { currencyPrecision } from './number';

export const showAccountTemporarilyLocked = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Account Temporarily Locked',
    html: `
    <div id="error-message">
      <p>
        You have entered the wrong 6-digit OTP too many times. To keep your account secure after a concerning number of failed attempts, we decided to temporarily lock it.
      </p>
      <p>
        We sent you an email with instructions on how to recover your account. Please check your inbox to get started.
      </p>
    </div>  
    `,
    confirmButtonText: 'Okay',
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

export const showUnauthorised = () => {
  Swal.fire({
    title: 'Unauthorized Access',
    html: `
    <div id="error-message">
      <p>
      You are trying to open a page that you are not allowed to access or your login session has expired
      </p>
    </div>  
    `,
    icon: 'warning',
    footer: 'Please close this window and try to log-in again.',
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

export const showSessionExpired = () => {
  Swal.fire({
    title: 'Session Expired',
    html: `
    <div id="error-message">
      <p>
      Please close this window and re-open from the Funds page to continue making transactions. Thank you!
      </p>
    </div>  
    `,
    icon: 'warning',
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

export const showRejectCashInSuccess = () => {
  Swal.fire({
    title: 'Success',
    html: `
    <div id="success-message">
      <p>
      Your request for cancellation is received. We have also sent an email to you to confirm the cancellation.
      </p>
    </div>  
    `,
    icon: 'success',
    footer: `
    <p>
    Not receiving any email? Check your Spam/Junk folder or&nbsp;<a id="contact-us-link" target="_blank" rel="noopener noreferrer" href="https://support.pdax.ph/">contact us</a> after 15 minutes.</p>`,
    confirmButtonText: 'Okay',
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

interface ISuccessProps {
  amount: number;
  fee?: number;
  reqId: string;
  method: string;
  mode: 'cashIn' | 'cashOut';
}

export const showSuccess = (props: ISuccessProps) => {
  const { amount, fee, reqId, mode, method } = props;
  Swal.fire({
    title: 'Transaction Created',
    html: `
<div class="value"><strong>Method:</strong> <span>${getMethodFullName(
      method,
    )}</span></div>
<div class="value"><strong>Amount:</strong> <span>${currencyPrecision(
      amount,
    )} PHP</span></div>
${fee ? '<div class="value"><strong>Fee:</strong> <span>' : ''}
${fee ? currencyPrecision(fee) : ''}
${fee ? ' PHP</span></div>' : ''}
<div class="value"><strong>Transaction ID:</strong> <span>${reqId}</span></div></br>
    ${
      mode === 'cashIn'
        ? 'Please check your email for further instructions to complete this transaction.'
        : "Request sent for processing. We've sent you an email containing the details about your transaction."
    }
`,
    icon: 'success',
    footer: `
    <p>Not receiving any email? Check your Spam/Junk folder or&nbsp;<a id="contact-us-link" target="_blank" rel="noopener noreferrer" href="https://support.pdax.ph/">contact us</a> after 15 minutes.</p>`,
    confirmButtonText: 'Okay',
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

interface IErrorProps {
  title?: string;
  reqId?: string;
  errorMessage?: string;
}

export const showError = (props: IErrorProps) => {
  const { title, reqId, errorMessage } = props;
  Swal.fire({
    title: title || 'Unable to Process Payment',
    html: `
${reqId ? '<div class="value">Transaction ID: <span>' : ''} 
${reqId || ''} 
${reqId ? '</span></div></br>' : ''}
<div id="error-message">
  <p>
    ${errorMessage || 'Something went wrong, Please try again after sometime.'}
  </p>
</div>
`,
    icon: 'error',
    footer: `Still having issues? Please&nbsp;<a id="contact-us-link" target="_blank" rel="noopener noreferrer" href="https://support.pdax.ph/">contact us</a>.`,
    confirmButtonText: 'Okay',
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

export const showRedirect = () => {
  Swal.fire({
    html: `
<div id="redirect-container" class="flex" style="align-items: center;">
<div class="sk-chase">
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
</div> 
<div style="margin-left: 28px;">Redirecting you to checkout page...</div>
</div>`,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};
