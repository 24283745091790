import {
  IAsyncActionTypes,
  IFilterableActionTypes,
} from './action-types.interface';

export const createAsyncActionTypes = (name: string): IAsyncActionTypes => {
  const actionName = name.toUpperCase();
  return {
    requesting: `${actionName}_REQUESTING`,
    success: `${actionName}_SUCCESS`,
    failed: `${actionName}_FAILED`,
    reset: `${actionName}_RESET`,
  };
};

export const createFilterableActionTypes = (
  name: string,
): IFilterableActionTypes => {
  const actionName = name.toUpperCase();
  return {
    changeSearch: `${actionName}_CHANGE_SEARCH`,
    changeCategory: `${actionName}_CHANGE_CATEGORY`,
    addFilter: `${actionName}_ADD_FILTER`,
    removeFilter: `${actionName}_REMOVE_FILTER`,
    updateLimit: `${actionName}_UPDATE_LIMIT`,
    updatePage: `${actionName}_UPDATE_PAGE`,
  };
};
