import { combineReducers } from 'redux';
import {
  FETCH_ALL_CHANNELS,
  FETCH_USER_DETAILS,
  FETCH_CASH_LIMIT,
  FETCH_RECENT_TRANSACTION,
  FETCH_STATES,
  FETCH_BANKS,
  CREATE_PAYMENT,
  REJECT_CASHIN,
  REQUEST_OTP,
  VERIFY_OTP,
} from './invoices.action-types';
import { createAsyncStateReducer } from '../reducers.utils';

const allChannelsListReducer = createAsyncStateReducer(FETCH_ALL_CHANNELS);
const userDetailsReducer = createAsyncStateReducer(FETCH_USER_DETAILS);
const cashLimitsReducer = createAsyncStateReducer(FETCH_CASH_LIMIT);
const recentTransactionsListReducer = createAsyncStateReducer(
  FETCH_RECENT_TRANSACTION,
);
const statesListReducer = createAsyncStateReducer(FETCH_STATES);
const banksListReducer = createAsyncStateReducer(FETCH_BANKS);
const createPaymentReducer = createAsyncStateReducer(CREATE_PAYMENT);
const rejectCashInReducer = createAsyncStateReducer(REJECT_CASHIN);

const requestOtpReducer = createAsyncStateReducer(REQUEST_OTP);
const verifyOtpReducer = createAsyncStateReducer(VERIFY_OTP);

const invoicesReducer = combineReducers({
  allChannelsListState: allChannelsListReducer,
  userDetailsState: userDetailsReducer,
  cashLimitsState: cashLimitsReducer,
  recentTransactionsListState: recentTransactionsListReducer,
  statesListState: statesListReducer,
  banksListState: banksListReducer,
  createPaymentState: createPaymentReducer,
  rejectCashInState: rejectCashInReducer,
  requestOtpState: requestOtpReducer,
  verifyOtpState: verifyOtpReducer,
});

export default invoicesReducer;
