const dev = {
  API_BASE_URL: 'https://api-dev.trade.pdax.ph',
  PAYMENTS_BASE_URL: 'https://services-dev.pdax.ph/payments/controller/anxone',
  WEB_V2_URL: 'https://dev.web.pdax.ph',
};

const test = {
  API_BASE_URL: 'https://api-test.trade.pdax.ph',
  PAYMENTS_BASE_URL: 'https://services-test.pdax.ph/payments/controller/anxone',
  WEB_V2_URL: 'https://test.web.pdax.ph',
};

const stage = {
  API_BASE_URL: 'https://api-stage.trade.pdax.ph',
  PAYMENTS_BASE_URL:
    'https://services-stage.pdax.ph/payments/controller/anxone',
  WEB_V2_URL: 'https://test.web.pdax.ph',
};

const prod = {
  API_BASE_URL: 'https://api.trade.pdax.ph',
  PAYMENTS_BASE_URL: 'https://services.pdax.ph/payments/controller/anxone',
  WEB_V2_URL: 'https://web.pdax.ph',
};

const config = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
    case 'Dev':
    case 'develop':
    case 'Develop':
      return dev;
    case 'test':
    case 'Test':
      return test;
    case 'stage':
    case 'Stage':
      return stage;
    case 'prod':
    case 'Prod':
    case 'production':
    case 'Production':
      return prod;
    default:
      return dev;
  }
};

export default config();
