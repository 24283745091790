export const OPEN_FORM_FRAGMENT = 'openFormFragment';
export const CLOSE_FORM_FRAGMENT = 'closeFormFragment';
export const OPEN_TXN_DETAIL = 'openTxnDetail';
export const CLOSE_TXN_DETAIL = 'closeTxnDetail';
export const OPEN_INSTRUCTIONS_FRAGMENT = 'openInstructionsFragment';
export const CLOSE_INSTRUCTIONS_FRAGMENT = 'closeInstructionsFragment';
export const TRIGGER_OPEN_INSTRUCTIONS_FRAGMENT =
  'triggerOpenInstructionsFragment';
export const TRIGGER_CLOSE_INSTRUCTIONS_FRAGMENT =
  'triggerCloseInstructionsFragment';
export const CASHOUT_TO_MYOWNACCOUNT_FRAGMENT = 'cashOutToMyOwnAccountFragment';
export const CASHOUT_TO_OTHERS_FRAGMENT = 'cashOutToOthersFragment';
