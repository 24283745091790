import {
  OPEN_FORM_FRAGMENT,
  CLOSE_FORM_FRAGMENT,
  OPEN_TXN_DETAIL,
  CLOSE_TXN_DETAIL,
  OPEN_INSTRUCTIONS_FRAGMENT,
  CLOSE_INSTRUCTIONS_FRAGMENT,
  TRIGGER_OPEN_INSTRUCTIONS_FRAGMENT,
  TRIGGER_CLOSE_INSTRUCTIONS_FRAGMENT,
  CASHOUT_TO_MYOWNACCOUNT_FRAGMENT,
  CASHOUT_TO_OTHERS_FRAGMENT,
} from './form.constant';

const initialData = {
  formState: null,
  txnDetailState: null,
  instructionsState: null,
  instructionTriggerState: null,
  cashOutToState: null,
};

export default (
  state = initialData,
  { type, data }: { type: string; data: any },
) => {
  switch (type) {
    case OPEN_FORM_FRAGMENT:
      return {
        ...state,
        formState: data,
      };
    case CLOSE_FORM_FRAGMENT:
      return {
        ...state,
        formState: null,
      };
    case OPEN_TXN_DETAIL:
      return {
        ...state,
        txnDetailState: data,
      };
    case CLOSE_TXN_DETAIL:
      return {
        ...state,
        txnDetailState: null,
      };
    case OPEN_INSTRUCTIONS_FRAGMENT:
      return {
        ...state,
        instructionsState: data,
      };
    case CLOSE_INSTRUCTIONS_FRAGMENT:
      return {
        ...state,
        instructionsState: null,
      };
    case TRIGGER_OPEN_INSTRUCTIONS_FRAGMENT:
      return {
        ...state,
        instructionTriggerState: data,
      };
    case TRIGGER_CLOSE_INSTRUCTIONS_FRAGMENT:
      return {
        ...state,
        instructionTriggerState: null,
      };
    case CASHOUT_TO_MYOWNACCOUNT_FRAGMENT:
      return {
        ...state,
        cashOutToState: {
          label: 'My Own Account',
          key: 'myOwnAccount',
        },
      };
    case CASHOUT_TO_OTHERS_FRAGMENT:
      return {
        ...state,
        cashOutToState: {
          label: 'Others',
          key: 'others',
        },
      };
    default:
      return state;
  }
};
