import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './redux';
import ReactHttpRequest from './utils/http';
import { INavigation } from './utils/navigation/interface';
import { RouterNavigation } from './utils/navigation';
import { IHttpRequest } from './utils/http/interface';

export interface ReduxThunkExtraArguments {
  navigation: INavigation;
  httpRequest: IHttpRequest;
}

const applicationStore = createStore(
  reducers,
  applyMiddleware(
    reduxThunk.withExtraArgument<ReduxThunkExtraArguments>({
      navigation: RouterNavigation,
      httpRequest: ReactHttpRequest,
    }),
  ),
);

export const StoreDispatcher = applicationStore.dispatch;

export default applicationStore;
