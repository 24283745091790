import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { Provider } from 'react-redux';
import App from './App';
import applicationStore from './root-store';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={applicationStore}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// serviceWorker.register();
serviceWorker.unregister();
