import { createAsyncActionTypes } from '../action-types.utils';

export const FETCH_ALL_CHANNELS = 'fetchAllChannels';
export const FETCH_USER_DETAILS = 'fetchUserDetails';
export const FETCH_CASH_LIMIT = 'fetchCashLimit';
export const FETCH_RECENT_TRANSACTION = 'fetchRecentTransaction';
export const FETCH_STATES = 'fetchStates';
export const FETCH_BANKS = 'fetchBanks';
export const CREATE_PAYMENT = 'createPayment';
export const REJECT_CASHIN = 'rejectCashIn';

export const REQUEST_OTP = 'requestOtp';
export const VERIFY_OTP = 'verifyOtp';

export const requestOtpActionTypes = createAsyncActionTypes(REQUEST_OTP);
export const verifyOtpActionTypes = createAsyncActionTypes(VERIFY_OTP);

export const fetchAllChannelsActionTypes =
  createAsyncActionTypes(FETCH_ALL_CHANNELS);

export const fetchUserDetailsActionTypes =
  createAsyncActionTypes(FETCH_USER_DETAILS);

export const fetchCashLimitActionTypes =
  createAsyncActionTypes(FETCH_CASH_LIMIT);

export const fetchRecentTransactionActionTypes = createAsyncActionTypes(
  FETCH_RECENT_TRANSACTION,
);

export const fetchStatesActionTypes = createAsyncActionTypes(FETCH_STATES);

export const fetchBanksActionTypes = createAsyncActionTypes(FETCH_BANKS);

export const createPaymentActionTypes = createAsyncActionTypes(CREATE_PAYMENT);

export const rejectCashInActionTypes = createAsyncActionTypes(REJECT_CASHIN);
