import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';

// import FailedPage from './components/statuses/failed';
// import Header from './components/header';
// import ProtectedInvoiceRoute from './utils/protected-invoice-route';
// import StatusPage from './components/statuses';
// import SuccessPage from './components/statuses/success';
import WebV2Redirection from './components/WebV2Redirection/WebV2Redirection';
import { isSessionExpired } from './utils/auth';

function App() {
  const [isExpired, setIsExpired] = useState(false);

  if (isSessionExpired()) {
    if (!isExpired) {
      setIsExpired(true);
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        {!isExpired && <Route path="/" element={<WebV2Redirection />} />}
      </Routes>
      {/* All routes will now be redirected to WebV2Redirection component */}
      {/* <Header />
      <Routes>
        <Route path="/status" element={<StatusPage />}>
          <Route path="success" element={<SuccessPage />} />
          <Route path="failed" element={<FailedPage />} />
        </Route>
        {!isExpired && <Route path="/" element={<ProtectedInvoiceRoute />} />}
      </Routes> */}
    </BrowserRouter>
  );
}

export default App;
