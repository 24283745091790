import { showSessionExpired } from './notifications';

declare const performance: any;
const timeOutDuration = 9 * 60 * 1000; // 9 minutes

export const isSessionExpired = (): boolean => {
  const allowedTestTypes = [
    'Prod',
    'prod',
    'production',
    'Production',
    'Stage',
    'stage',
    'Staging',
    'staging',
  ];

  if (!allowedTestTypes.includes(process.env.REACT_APP_ENV || '')) {
    return false;
  }

  setTimeout(() => {
    showSessionExpired();
  }, timeOutDuration);

  if (
    performance &&
    performance.getEntriesByType('navigation') &&
    performance.getEntriesByType('navigation')[0] &&
    performance.getEntriesByType('navigation')[0].type &&
    performance.getEntriesByType('navigation')[0].type === 'reload'
  ) {
    showSessionExpired();
    return true;
  }

  if (
    performance &&
    performance.navigation &&
    performance.navigation.type &&
    performance.navigation.type === performance.navigation.TYPE_RELOAD
  ) {
    showSessionExpired();
    return true;
  }

  return false;
};
