import { Reducer } from 'redux';
import { createAsyncActionTypes } from './action-types.utils';
import { IAsyncActionTypes } from './action-types.interface';
import { createAsyncState } from './states.utils';
import { IAsyncState } from './state.interface';
import { IActionWithPayload } from './actions.utils';

export const createAsyncStateReducer = <Data>(
  name: string,
): Reducer<IAsyncState<Data>, IActionWithPayload<Data>> => {
  const asyncActionTypes: IAsyncActionTypes = createAsyncActionTypes(name);
  return (
    state: IAsyncState<Data> = createAsyncState(),
    action: IActionWithPayload<Data>,
  ) => {
    const { type, payload } = action;

    switch (type) {
      case asyncActionTypes.reset:
        return createAsyncState();
      case asyncActionTypes.requesting:
        return {
          ...state,
          isRequesting: true,
          error: null,
          data: null,
        };
      case asyncActionTypes.success:
        return {
          ...state,
          isRequesting: false,
          data: payload.data,
        };
      case asyncActionTypes.failed:
        return {
          ...state,
          isRequesting: false,
          error: payload.error,
        };
      default:
        return state;
    }
  };
};
