import './WebV2Redirection.scss';

import CashIn from '../../assets/cash_in.svg';
import CashOut from '../../assets/cash_out.svg';
import Config from '../../config';
import DefaultBg from '../../assets/default_bg.svg';
import PdaxBanner from '../../assets/pdax_banner.svg';
import React from 'react';
import { RouterNavigation } from '../../utils/navigation';
import { showUnauthorised } from '../../utils/notifications';

type PathActionProps = 'cash_in' | 'cash_out';

const WebV2Redirection = () => {
  const path: PathActionProps = RouterNavigation.getUrlQueryParam('action');
  const actionName = path?.replace('_', '-');

  if (!RouterNavigation.getUrlQueryParam('token')) {
    showUnauthorised();
    return null;
  }

  return (
    <div className="webv2-redirection-container">
      <div className="header">
        <img src={PdaxBanner} alt="PDAX" width={139} />
      </div>
      <div
        className="description"
        style={{ backgroundImage: `url(${DefaultBg})` }}
      >
        <img
          src={path === 'cash_in' ? CashIn : CashOut}
          alt="Cash In/Out"
          width={112}
        />
        <p>
          {actionName.charAt(0).toUpperCase() + actionName.slice(1)} <br /> is
          now in our new exchange
        </p>
      </div>
      <p className="instruction">
        Go to our new web exchange to {actionName}.
        <br />
        Just login and you’ll be redirected to the {actionName} page.
      </p>
      <div className="action-buttons">
        <button
          className="goToButton"
          onClick={() => {
            window
              .open(Config.WEB_V2_URL + `/fiat/${actionName}`, '_blank')
              ?.focus();
            window.close();
          }}
        >
          Go to the new exchange
        </button>
        <button className="closeButton" onClick={() => window.close()}>
          Close
        </button>
      </div>
    </div>
  );
};

export default WebV2Redirection;
