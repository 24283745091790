import axios, { AxiosRequestConfig } from 'axios';
import { IHttpRequest } from './interface';

// const config: AxiosRequestConfig = {
//   headers: {
//     'Content-Type': 'application/json',
//     Authorization: Cookies.get('access_token'),
//   },
// };

export const ReactHttpRequest: IHttpRequest = {
  get: (url: string, config: AxiosRequestConfig): Promise<any> => {
    return axios.get(url, config);
  },
  post: (url: string, body: any, config: AxiosRequestConfig): Promise<any> => {
    return axios.post(url, body, config);
  },
  delete: (url: string, config: AxiosRequestConfig): Promise<any> => {
    return axios.delete(url, config);
  },
  put: (url: string, body: any, config: AxiosRequestConfig): Promise<any> => {
    return axios.put(url, body, config);
  },
  patch: (url: string, body: any, config: AxiosRequestConfig): Promise<any> => {
    return axios.patch(url, body, config);
  },
};

export default ReactHttpRequest;
