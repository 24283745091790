import { createBrowserHistory } from 'history';
import { INavigation } from './interface';
import qs from 'qs';

export const reactRouterHistory = createBrowserHistory();

export const RouterNavigation: INavigation = {
  currentPath: (): string => {
    return reactRouterHistory.location.pathname;
  },

  getUrlQueryParam: (name: string): any => {
    const query = qs.parse(reactRouterHistory.location.search, {
      ignoreQueryPrefix: true,
    });
    return query[name];
  },

  navigateTo: path => {
    reactRouterHistory.push(path);
  },

  popAndNavigateTo: path => {
    reactRouterHistory.replace(path);
  },

  backward: () => {
    reactRouterHistory.back();
  },

  forward: () => {
    reactRouterHistory.forward();
  },
};
